<template>
    <div>
        <card-header title="Make Payment" icon="fa-credit-card"/>

        <card-body>
            <card-list>
                <registration-list-item
                    :card="card"
                    :registration="registration"
                    v-if="registration.id"
                    @click="$openCard('registration', {registrationId: registration.id}, card)"
                />
                <subheader-list-item icon="fa-align-left" title="Payment Information"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Payment Method" label-position="inside">
                                <b-select expanded v-model="payment.method">
                                    <option :value="m.id" v-for="m in plan.methods">Card ending in {{ m.last }}</option>
                                    <option value="check">Other Payment (Check/Credit/Etc.)</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Description" label-position="inside">
                                <b-input v-model="payment.description"></b-input>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
                <container-list-item padded>
                    <div class="columns item" v-for="i in plan.items" v-if="i.paid === 0">
                        <div class="column is-1">
                            <b-switch v-model="i.selected"/>
                        </div>
                        <div class="column pl-4">
                            <div><i class="far fa-fw fa-usd-square mr-2"></i>{{ i.amount * i.quantity | currency }}</div>
                            <div><i class="far fa-fw fa-align-left mr-2"></i>{{ i.title }} ({{ i.type | capitalize }})</div>
                            <div><i class="far fa-fw fa-calendar-day mr-2"></i>Due {{ i.due_on | moment('MMMM Do, YYYY') }}</div>
                        </div>
                    </div>
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <div class="columns">
                <div class="column has-text-left is-size-6">
                    <strong>Charge Amount: </strong>{{ chargeAmount | currency }}
                </div>
                <div class="column pull-right">
                    <b-button type="is-primary" size="is-small" :disabled="!payment.method || chargeAmount <= 0" @click="submit"><i class="fas fa-check mr-3"></i>Make Payment</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import DataRow from "../../TIER/components/DataRow";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import RegistrationListItem from "../../components/RegistrationListItem";

    export default {
        props: ['card', 'props'],
        components: {RegistrationListItem, SeparatorListItem, DataRow, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function() {
            return {
                plan: {
                    items: []
                },
                payment: {
                    description: 'Plan Payment',
                    method: null
                },
                registration: {
                    user: {}
                }
            };
        },
        computed: {
            chargeAmount: function() {
                let amount = 0;

                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    if (item.selected) amount += (item.amount * item.quantity);
                }

                return amount;
            }
        },
        methods: {
            loadPlan: function(force) {
                this.$emit('loading', true);
                http.get('/api/payment_plans/' + this.props.planId, {force}).then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        response.data[i].selected = false;
                    }
                    this.plan = response.data;
                    this.payment.method = (this.plan.methods.length === 1) ? this.plan.methods[0].id : null;

                    http.get('/api/registrations/' + this.plan.registration_id, {}).then(response => {
                        this.registration = response.data;
                        this.$emit('loading', false);
                    });
                });
            },
            submit() {
                this.$emit('loading', true);

                let items = [];
                for (let i = 0; i < this.plan.items.length; i++) {
                    const item = this.plan.items[i];
                    if (item.selected) items.push({id: item.id, title: item.title, amount: item.amount, quantity: item.quantity, type: item.type});
                }
                http.post('/api/payment_plans/' + this.plan.id + '/charge', {amount: this.chargeAmount, items, ...this.payment}, {force: true}).then(response => {
                    this.$reloadCard('payment-plan', {planId: this.plan.id});
                    this.$reloadCard('registration', {registrationId: this.plan.registration_id});
                    this.$reloadCard('billing', {});
                    this.$reloadCard('billing-due', {});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    window.alert(err.response.data.err);
                    window.alert('The transaction failed to process.  Review transactions for full details.');
                    this.payment.method = null;
                    this.$emit('loading', false);
                });
            }
        },
        watch: {
            'props.planId': function() {
                this.loadPlan(true);
            }
        },
        mounted() {
            this.payment.plan_id = this.props.planId;
            this.loadPlan();
        }
    };
</script>

<style lang="scss" scoped>
    .item {
        border-bottom: 1px solid #e7e7e7;

        &:last-child {
            border: none;
        }
    }
</style>
