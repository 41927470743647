<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-suitcase" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="registration-title" v-if="registration.user">{{registration.user.last_name}}, {{registration.user.first_name}}</div>
            <div class="meta" v-if="registration.second_user"><i class="far fa-fw fa-user-friends mr-2"></i>{{registration.second_user.last_name}}, {{registration.second_user.first_name}} </div>
            <div class="meta" v-if="registration.second_firstname"><i class="far fa-fw fa-share-all mr-2"></i>{{registration.second_lastname}}, {{registration.second_firstname}} </div>
            <div class="meta" v-if="registration.user.city"><i class="far fa-fw fa-map-marker mr-2"></i>{{registration.user.city}}, {{registration.user.state}}</div>
            <div class="meta" v-if="!hideTrip && registration.trip"><i class="far fa-fw fa-tennis-ball mr-2"></i>{{registration.trip.title}}</div>

            <div class="mt-1">
                <span v-if="registration.type==='waitlist'" class="mr-1"><b-tag type="is-warning"><i class="fas fa-user mr-1"></i>Wait List</b-tag> </span>
                <span v-if="registration.second_type==='waitlist'" class="mr-1"><b-tag type="is-warning"><i class="fas fa-user-friends mr-1"></i>Wait List</b-tag> </span>

                <span v-if="registration.type==='staff'" class="mr-1"><b-tag type="is-primary"><i class="fas fa-user mr-1"></i>Staff</b-tag> </span>
                <span v-if="registration.second_type==='staff'" class="mr-1"><b-tag type="is-primary"><i class="fas fa-user-friends mr-1"></i>Staff</b-tag> </span>

                <span v-if="registration.type==='coach'" class="mr-1"><b-tag type="is-info"><i class="fas fa-user mr-1"></i>Coach</b-tag> </span>
                <span v-if="registration.second_type==='coach'" class="mr-1"><b-tag type="is-info"><i class="fas fa-user-friends mr-1"></i>Coach</b-tag> </span>

                <span v-if="registration.type==='cancelled'" class="mr-1"><b-tag type="is-danger"><i class="fas fa-user mr-1"></i>Cancelled</b-tag> </span>
                <span v-if="registration.second_type==='cancelled'" class="mr-1"><b-tag type="is-danger"><i class="fas fa-user-friends mr-1"></i>Cancelled</b-tag> </span>
            </div>

        </template>
        <template v-slot:status>
            <div class="stats">
                <span class="stat" v-if="complete"><b-tooltip position="is-left" label="Ready to Travel"><i class="fas fa-check status-check"></i></b-tooltip></span>
                <span class="stat" v-else><b-tooltip position="is-left" label="Not Ready"><i class="fas fa-ban has-text-warning"></i></b-tooltip></span>
                <span class="stat" v-if="tasks && registration.tasks && registration.tasks.total !== 0"><b-tag type="is-warning" class="stat" ><i class="fas fa-tasks mr-1"></i>{{registration.tasks.total}}</b-tag></span>
            </div>
        </template>
        <template v-slot:details>
            <div class="user-details">
                <toolbar class="toolbar">
                    <button class="icon-only" @click.stop="$openCard('registration', {registrationId: registration.id}, card)"><i class="far fa-suitcase"></i></button>
                    <button class="icon-only" @click.stop="$openCard('flights', {registrationId: registration.id}, card)"><i class="far fa-plane"></i></button>
                    <button class="icon-only" @click.stop="$openCard('itinerary', {tripId: registration.trip.id, registrationId: registration.id}, card)"><i class="far fa-calendar-alt"></i></button>
                    <button class="icon-only" @click.stop="$openCard('payment-plan', {planId: registration.payment_plan.id, regId: registration.id}, card)" v-if="registration.payment_plan && registration.payment_plan.id"><i class="far fa-usd-square"></i></button>
                    <button class="icon-only" @click.stop="$openCard('customer', {userId: registration.user_id}, card)"><i class="far fa-user"></i></button>
                    <button class="icon-only" @click.stop="$openCard('customer', {userId: registration.second_id}, card)" v-if="registration.second_id"><i class="far fa-user-friends"></i></button>
                    <button class="icon-only" @click.stop="$openCard('trip', {tripId: registration.trip.id}, card)"><i class="far fa-tennis-ball"></i></button>
                    <button class="icon-only" @click.stop=""><i class="far fa-pencil"></i></button>
                </toolbar>

                <div class="data mt-1">
                    <data-row mini title="Registered">{{registration.created_at | moment('MMMM Do, YYYY, h:mm A')}}</data-row>
                    <data-row mini title="ID">{{registration.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'RegistrationListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            registration: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            hideTrip: {
                type: Boolean,
                default: false
            },
            tasks: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        },
        computed: {
            complete: function() {
                return (this.registration.itinerary_complete
                && this.registration.flights_complete
                && this.registration.addons_complete
                && this.registration.questions_complete
                && this.registration.ready_to_travel);
            }
        }
    }
</script>

<style lang="scss" scoped>
    li {
        color: darken($t-primary, 10);

        .registration-title {
            font-size: .9rem;
            font-weight: 700;
            margin-top: 1px;
        }

        padding: 0.5rem 0;

        .user-details {
            margin-top: 0.5rem;
        }
    }

    .status-check {
        color: $t-primary;
    }
</style>
